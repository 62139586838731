<template>
  <div>
    <v-col class="mx-1 my-2  ">
      <v-card>
        <v-row class="px-2 py-2">
          <v-spacer />

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="startDate"
                  label="Tarih"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
              >
                <v-spacer />
                <Button
                  :text="true"
                  color="primary"
                  @click="menu = false"
                >
                  Kapat
                </Button>
                <Button
                  :text="true"
                  color="primary"
                  @click="$refs.menu.save(startDate)"
                >
                  Seç
                </Button>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <Select
              v-model="santralModel"
              :items="santralList"
              label="Santral"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <Select
              v-model="combinerBox"
              :items="combinerboxList[santralModel]"
              label="Stringbox"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="2"
            md="2"
            lg="2"
            xl="1"
            class="  py-0 my-0  mb-2"
          >
            <Button
              color="primary"
              @click="getSantralDailyDataChart"
            >
              Filtrele
            </Button>
          </v-col>
        </v-row>

        <v-col
          v-if="componentsize"
          cols="12"
        >
          <div
            v-if="dcCurrentData"
            class="pt-10"
          >
            <echart
              id="dc_Current_Data_"
              class-name="widget-content  pt-10"
              height="450px"
              width="100%"
              :chart-data="dcCurrentData"
            />
          </div>
          <v-divider class="" />

          <div
            v-if="string_mpptDisplay === true"
            class="pt-2"
          >
            <echart
              id="dc_string_mppt_"
              class-name="widget-content  pt-10"
              height="400px"
              width="100%"
              :chart-data="string_mpptData"
            />
          </div>
          <v-divider class="" />
          <div
            v-if="dcTotalData"
            class="pt-2"
          >
            <echart
              id="dc_Total_Data_"
              class-name="widget-content  pt-10"
              height="400px"
              width="100%"
              :chart-data="dcTotalData"
            />
          </div>
          <v-divider class="" />

          <div
            v-if="dcVoltageDataDisplay == true"
            class="pt-10"
          >
            <echart
              id="dc_Voltage_Data_"
              class-name="widget-content pt-10"
              height="450px"
              width="100%"
              :chart-data="dcVoltageData"
            />
          </div>
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>

import * as Heatmap from '@/api/CombinerBox/Heatmap';
import Echart from '@/views/components/Echart/Chart.vue';
import moment from 'moment';
import { getStringboxDataList } from '@/api/Request/stringbox';
import helper from '@/api/helper';

export default {
  name: 'Dashboard',
  components: {
    Echart,
  },
  data: () => ({
    santralModel: '',
    combinerBox: '',
    startDate: moment().format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format('YYYY'),
    minutesDailyEnergydate: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    chart_data_id: 'chartData1',
    dc_data_id: 'Dcvoltage1',
    chartData: {},
    Dcvoltage: {},
    componentsize: 1,
    measureList: [],
    measure_List: [],
    santralInfo: {},
    combinerboxList: [],
    dcCurrentMeasures: [],
    dcVoltageMeasures: [],
    dcCurrentData: [],
    dcVoltageData: [],
    dcTotalData: [],
    string_mpptMeasures: [],
    string_mpptData: [],
    string_mpptDisplay: [],
    dcVoltageDataDisplay: false,
  }),
  computed: {
    santralList() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralList = [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      santralRawData.forEach((item) => {
        santralList.push({
          text: item.santral,
          value: item.santralCode,
        });
      });

      // eslint-disable-next-line consistent-return
      return santralList;
    },
    santralData() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralData = {};
      santralRawData.forEach((item) => {
        santralData[item.santralCode] = item;
      });
      // eslint-disable-next-line consistent-return
      return santralData;
    },
  },
  watch: {
    santralModel() {
      const rest = this.santralInfo[this.santralModel];
      const combinerboxList = [];

      Object.keys(rest).forEach((key) => {
        combinerboxList.push({
          text: rest[key].STRINGBOXNAME,
          value: rest[key].STRINGBOX,
        });
      });

      this.combinerboxList[this.santralModel] = combinerboxList;
      this.combinerBox = combinerboxList[0].value;
    },

    combinerBox() {
      this.measure_List = [];
      this.dcVoltageData = [];
      this.dcVoltageDataDisplay = false;
      const rest = this.santralInfo[this.santralModel];
      this.setMeasures(rest[this.combinerBox]);
    },
  },
  created() {},
  mounted() {
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    let k = 0;
    function myTimer1() {
      if (k > 30) {
        clearInterval(myVar1);
      }

      k += 1;
      const { santralStringboxData } = self.$store.getters;

      if (Object.keys(santralStringboxData).length > 0) {
        self.santralInfo = santralStringboxData;
        self.santralModel = self.santralList[0].value;

        clearInterval(myVar1);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    setMeasures(rest) {
      this.componentsize += 1;
      const dcCurrentMeasures = [];
      const stringMppt = [];
      this.dcTotalData = [];
      this.dcCurrentData = [];

      const measureList = rest.general_measure_list;
      const dcVolMeAdd = ['DCCurrent', 'TempBoard'];

      Object.keys(measureList).forEach((k) => {
        if (
          measureList[k].value.indexOf('DCCurrent') > -1
          && measureList[k].value !== 'DCCurrent'
        ) {
          dcCurrentMeasures.push(measureList[k]);
        }

        // eslint-disable-next-line no-undef
        if (helper.isObjectKeysExist(rest, 'MPPT')) {
          if (measureList[k].value.indexOf('MPPT') > -1) {
            measureList[k].DC_Voltage = rest.MPPT[measureList[k].value];
            stringMppt.push(measureList[k]);
          }
        }

        if (dcVolMeAdd.indexOf(measureList[k].value) > -1) {
          stringMppt.push(measureList[k]);
        }
      });

      this.dcCurrentMeasures = dcCurrentMeasures;
      this.string_mpptMeasures = stringMppt;
      this.getSantralDailyDataChart();
    },

    getSantralDailyDataChart() {
      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.startDate,
          santral: this.santralData[this.santralModel].santral,
          stringbox: this.combinerBox,
          column: ['date'],
        },
      };

      this.dcCurrentData = [];
      this.string_mpptData = [];
      this.dcVoltageData = [];
      this.dcTotalData = [];

      const { stepTime } = this.$store.getters;
      const tmpdata = getStringboxDataList(params);
      const { santralCode, santral } = this.santralData[this.santralModel];
      const { STRINGBOXNAME } = this.santralInfo[santralCode][this.combinerBox];

      tmpdata.then((data) => {
        if (this.dcCurrentMeasures.length > 0) {
          const tempData = Heatmap.default.heatmapcomparisonHour(
            data,
            this.dcCurrentMeasures,
            STRINGBOXNAME,
            santral,
            stepTime,
          );

          tempData.then((res) => {
            this.dcCurrentData = res;
          });
        }

        if (this.string_mpptMeasures.length > 0) {
          const tempData1 = Heatmap.default.heatmapcomparisonHour(
            data,
            this.string_mpptMeasures,
            STRINGBOXNAME,
            santral,
            stepTime,
          );

          tempData1.then((res) => {
            this.string_mpptData = res;
            this.string_mpptDisplay = true;
          });
        }

        if (this.dcVoltageMeasures.length > 0) {
          const tempData1 = Heatmap.default.heatmapcomparisonHour(
            data,
            this.dcVoltageMeasures,
            STRINGBOXNAME,
            santral,
            stepTime,
          );

          tempData1.then((res) => {
            this.dcVoltageData = res;
            this.dcVoltageDataDisplay = true;
          });
        }

        if (this.measure_List.length > 0) {
          const tempData1 = Heatmap.default.heatmapcomparisonHour(
            data,
            this.measure_List,
            stepTime,
          );

          tempData1.then((res) => {
            this.dcTotalData = res;
          });
        }
      });
    },
  },
};
</script>
